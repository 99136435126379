<template>
  <div class="container">

    <div class="row justify-content-center align-items-center mt-5 mb-5" style="min-height: calc(100vh - 6rem)">
      <div class="col-12 col-sm" style="max-width: 400px" v-if="!isLoading">

        <div class="mb-3" v-html="message"></div>

        <div>
          <div class="row mt-4 justify-content-center">
            <div class="col-auto ">
              <div class="font-weight-bold"> กรุณาติดต่อ</div>
              <div class="small"> Tel. 092-249-4999 (จันทร์-ศุกร์ 10.00 - 19.00 น.)</div>
              <div class="small"> Facebook page : <a class="text-kss font-weight-bold"
                                                     href="https://m.me/stockradars"
                                                     target="_blank">StockRadars </a>
              </div>


              <a @click="$router.push(`/?item=${$route.query.item}`)"
                 class="mt-3 w-100 btn btn-primary mb-3">
                กลับหน้าสินค้า
              </a>

            </div>

          </div>
        </div>

      </div>
    </div>


  </div>
</template>

<script>
import store from '../store';
import {
  CHECK_TRANSACTION_STATUS,
  GET_ITEM_INFO, SET_RDP_USER_ID
} from "../store/actions.type";
import {mapState} from 'vuex';

export default {
  name: 'Callback',
  computed: {
    ...mapState({
      item: state => state.payment.itemInfo,
      transactionId: state => state.payment.transactionId,
      verifyCode: state => state.payment.verifyCode,
      user: (state) => state.payment.userInfo,
    }),
  },
  data() {
    return {
      form: {
        "unique_id": "",
        "transactionId": "",
      },
      errorMessage: {},
      message: null,
      isLoading: false
    }
  },
  async created() {

    if (!this.$route.query.item && this.$route.query.txn) {
      await this.$router.push('/message');
    }

    this.form.unique_id = this.$route.query.item;
    this.form.transactionId = this.$route.query.txn;

    await store.dispatch(GET_ITEM_INFO, this.form.unique_id);

    this.isLoading = true;
    let response = await store.dispatch(CHECK_TRANSACTION_STATUS, {
      transactionId: this.form.transactionId,
      phone: this.user.phone
    });
    this.isLoading = false;

    if (response.status === 'yes' && response.result.status === "PAID") {
      // set transactionId
      store.state.payment.transactionId = this.form.transactionId;
      store.state.payment.subTxn = response.result.sub_txn || [];
      store.state.payment.totalPrice = response.result.total_price || 0;
      await this.$router.push('/success');
    } else {
      this.message = `<div class="sub-title text-center">การชำระเงินไม่สำเร็จ <br>
                      กรุณาทำรายการใหม่อีกครั้ง</div>`
    }
  },
  methods: {}
}
</script>
